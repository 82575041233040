import React from "react"

import { StaticImage } from "gatsby-plugin-image"
import Layout from "../../components/layout/layout"
import SEO from "../../components/layout/seo"

export default function Informatyzacja() {
  const seo = {
    title: "Informatyzacja - projekt unijny w Medicus Dukla",
    description: "Przedmiotem projektu jest stworzenie oraz wdrożenie w pełni funkcjonalnej infrastruktury umożliwiającej wymianę elektronicznej dokumentacji medycznej.",
    url: "https://medicusdukla.pl/projekty-unijne/informatyzacja"
  }
  return (
    <Layout marginTop={'8rem'}>
      <SEO
        title={seo.title}
        description={seo.description}
        url={seo.url}
      />
        <div data-g="container" className="u-text-center">
            <div className="projects-logos">
                <StaticImage
                    className="a-img"
                    style={{width: 'auto'}}
                    src="../../images/it/projekt.png"
                    alt="fundusze europejskie, rzeczpospolita polska, podkarpackie, unia europejska"
                    quality={80}
                    formats={['auto', 'webp', 'avif']}
                />
            </div>
            <br/>
            <br/>
        </div>
      <section className="o-it" data-g="container grid">
        <div data-g="4 6@md">
          <h1 className="a-heading-medium">Poprawa dostępu do opieki medycznej poprzez informatyzację Medicus-Dukla Sp. z o.o.</h1>
          <p style={{ maxWidth: "450px" }}>
            W dniu 22.05.2020 r. została podpisana umowa o dofinansowanie projektu nr RPPK.02.01.00-18-0004/19-00 pomiędzy Niepublicznym Zakładem Opieki Zdrowotnej „Medicus-Dukla” Sp. z o.o. , a Województwem Podkarpackim w imieniu którego działa Zarząd Województwa Podkarpackiego, Instytucja Zarządzająca Regionalnym Programem Operacyjnym Województwa Podkarpackiego na lata 2014-2020. Przedmiotem projektu jest stworzenie oraz wdrożenie w pełni funkcjonalnej i operacyjnej infrastruktury teleinformatycznej umożliwiającej wymianę elektronicznej dokumentacji medycznej pomiędzy systemami funkcjonującymi w systemie ochrony zdrowia, w tym świadczenia usług on-line oraz wdrożenie elektronicznej dokumentacji medycznej (EDM), dostosowującej działalność jednostki do znowelizowanych przepisów prawa m.in. w zakresie wymagań interoperacyjności oraz ustawy o systemie informacji w ochronie zdrowia.
          </p>

            <h2 className={'a-heading-small'}>Medicus-Dukla Sp. z o.o. zintegrowany z Podkarpackim Systemem Informacji Medycznej</h2>
            <p>
                W wyniku przeprowadzonej informatyzacji staliśmy się członkiem podkarpackiej platformy e-zdrowia, dzięki której umożliwiamy pacjentom rejestrację do lekarzy przez internet.
            </p>
            <p>
                Już dziś wejdź na stronę <a href="https://m.psim.podkarpackie.pl/strona-glowna">https://m.psim.podkarpackie.pl/strona-glowna</a>
            </p>
            <p>
                Załóż konto i korzystaj z usługi e-rejstracji zarówno do naszej przychodni, jak i innych podmiotów leczniczych zintegrowanych z PSIM.”
            </p>
        </div>

        <div data-g="4 offset-8@md 5@md">
          <StaticImage className="a-img" src="../../images/it/hero.png" alt="komputer" quality={80} formats={['auto', 'webp', 'avif']}/>
        </div>
      </section>
      <div data-g="container" className="u-text-center">
        <br/><br/><br/><br/>
        <img className="a-img nfz" src="/images/homepage/nfz.png" alt="nfz" />
      </div>
    </Layout>
  )
}
